a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/*

Font size index:
  - 2rem = 32px
  - 1rem = 16px

/* Margins */

.margin-top-small {
  margin-top: 2rem;
}

.gap-small {
  gap: 0.5rem;
}

/* Workflow Builder */

#floating-workflow-menu {
  position: absolute;
  right: 1rem;
  top: 4rem;
  background: #f3f4f8;
  text-transform: uppercase;
  font-weight: 700;
  color: #39818e;
  z-index: 1;
}

.workflow-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.66rem;
  padding: 0.75rem;
  background: #4aa3b3;
  font-family: inherit;
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 3px;
  border: none;
}

.workflow-menu-item:hover {
  background: #3a808c;
  cursor: pointer;
}

.workflow-step-node {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 24px;
  background: #ffffff;
  border: 1px solid #c8dae2;
  border-radius: 5px;
  font-family: inherit;
  min-width: 15rem;
}

.workflow-step-node.no-pointer {
  cursor: default;
}

.workflow-step-node.active {
  border: 2px solid #4aa3b3;
}

.workflow-step-node>h4 {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

.workflow-step-task-subcount {
  color: #686c73;
}

#workflow-stepeditor {
  box-sizing: border-box;
  transition: 0.5s;
  border-right: 1px solid #c8dae2;
  background: #ffffff;
  height: 94vh;
  overflow-y: scroll;
}

.workflow-stepeditor__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 30px;
  gap: 36px;
  overflow-y: scroll;
}

.search-bar {
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  outline: none;
  border: 1px solid #c8dae2;
  border-radius: 5px;
  width: 100%;
  padding: 0.3rem;
}

.search-available-items-container {
  max-height: 15rem;
  overflow-y: scroll;
  margin-top: 1rem;
}

.search-empty-results {
  color: #686c73;
}

.workflow-stepeditor__step-title input {
  font-size: 1.5rem;
  font-family: inherit;
  border: none;
  width: 100%;
  background-color: inherit;
}

.workflow-stepeditor__step-title input:focus {
  outline: none;
  border-bottom: 0.2rem solid #4aa3b3;
}

.workflow-stepeditor__step-title input:invalid {
  outline: none;
  border-bottom: 0.2rem solid red;
}

.workflow-stepeditor__input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: #30363d;
}

.workflow-stepeditor__input-group>label {
  width: 100%;
}

.workflow-stepeditor__input-group-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
}

.workflow-stepeditor__subcontent-group {
  width: 100%;
}

.workflow-stepeditor__container>label {
  font-size: inherit;
  background-color: inherit;
}

.workflow-stepeditor__container textarea,
.workflow-stepeditor__container select {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #c8dae2;
  border-radius: 3px;
  font-family: inherit;
  padding: 0.5rem;
  font-size: inherit;
  background-color: inherit;
}

.workflow-stepeditor__container textarea:focus {
  outline: none;
  border: solid #4aa3b3;
}

.workflow-stepeditor__button-group {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.input-error-text {
  color: red;
  font-family: inherit;
  font-size: 0.75rem;
  height: 1rem;
}

.overlay {
  width: auto;
  height: auto;
  background: #f3f4f8;
  opacity: 0.5;
}

/* Buttons */

.sidepanel-button-row {
  width: inherit;
  position: sticky;
  bottom: 0;
  padding: 1rem 30px;
  background-color: #fff;
  border-top: 1px solid #c8dae2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  border-radius: 3px;
  border: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

button,
.pointer {
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
  background-color: #f3f4f8 !important;
  color: #686c73 !important;
}

.button.teal-bg {
  background: #4aa3b3;
  color: white;
}

.button.orange-bg {
  background: #ec5b30;
  color: white;
}

.button.teal-text {
  background: none;
  color: #4aa3b3;
}

.button.black-text {
  background: none;
  color: black;
}

.button-group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  align-self: flex-end;
}

/* Resources */
.resource__title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: #30363d;
}

.resource__remove-from-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

/* Text */
.workflow-stepeditor__subcontent-group {
  box-sizing: border-box;
  color: #686c73;
  padding-left: 2.25rem;
  padding-top: 1rem;
}

/* Select */
.select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

select:focus {
  outline: none;
  border: solid #4aa3b3;
}

/* Switch */
.switch {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 18px;
  background: #e1e5eb;
  /* Grey 2 */
  border: 1px solid #c8dae2;
  border-radius: 100px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 24px;
  width: 24px;
  left: -2px;
  bottom: -4px;
  background: #ffffff;
  border: 1px solid #c8dae2;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background: #f0fbfc;
  border: 1px solid #4aa3b3;
  border-radius: 100px;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
  background: #4aa3b3;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
}

.slider-label {
  position: absolute;
  left: 50px;
  cursor: pointer;
  color: #30363d;
}

/* Flexbox */

.flexrow {
  display: flex;
  flex-direction: row;
}

.flexrow-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Width */
.width-100 {
  width: 100%;
}

/* Position */
.sidebar-content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
}

/* Snackbar */
.snackbar__default {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.9rem;
  width: 151px;
  height: 32px;
  background: #30363d;
  border-radius: 3px;
  color: white;
}

/* Snackbar action */
.css-1kr9x0n-MuiSnackbarContent-action {
  padding-left: 5px !important;
}

/* Modal */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 2;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  border-radius: 0.3rem;
  margin: 15% auto;
  max-width: 50%;
  overflow-y: overlay;
}

.modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  padding: 0rem 1rem;
  border-bottom: 1px solid #c8dae2;
}

.modal-button-row {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 0.4rem;
  border-top: 1px solid #c8dae2;
}

.modal-body-50-percent {
  width: 50%;
  padding: 1rem;
}

.border-right-gray {
  border-right: 1px solid #c8dae2;
}

/* Login Page */

/* These styles remove the underline from the Sign Up link */
a.sign-up-link:link {
  text-decoration: none;
}

a.sign-up-link:visited {
  text-decoration: none;
}

a.sign-up-link:hover {
  text-decoration: none;
}

a.sign-up-link:active {
  text-decoration: none;
}

div.react-datepicker-popper {
  z-index: 3;
}

.pyro {
  width: 100vw;
}

.pyro>.before,
.pyro>.after {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}